import { BrowserRouter } from "react-router-dom";
// import ReactDOM from "react-dom";
// import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
import Router from "./router";
import { createRoot } from 'react-dom/client';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
// import "@fortawesome/fontawesome-free/css/all.min.css";

const App = () => (
  <BrowserRouter>
      <Router />
  </BrowserRouter>
);


const container = document.getElementById("root");
const root = createRoot(container!);

// ReactDOM.render(<App />, document.getElementById("root"));
root.render(<App />);

