import { Row, Col } from "antd";
import Container from "../../common/Container";

import {
  FooterSection,
  Para,
  Chat,
  Language,
} from "./styles";

const Footer = () => {

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{"Contacto"}</Language>
              <a href ="tel:+525555367616"> 
                <Chat>{`Llámanos`}</Chat>
              </a>
              <a href="mailto:kathylizhang@hotmail.com">
                <Chat>{`Envíanos un mensaje`}</Chat>
              </a>
            </Col>
            <Col lg={8} md={10} sm={12} xs={12}>
              <Language>{"Dirección"}</Language>
              <Para>Lic. Julián Grajales Robles 38B, </Para>
              <Para>Col del Valle Nte, Benito Juárez, 03100 </Para>
              <Para>Ciudad de México, Mexico</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default (Footer);
